.card {
  position: relative;
  border-radius: 0;
  width: 100%;
  background-color: $bg-switch;
  margin-bottom: $spacer;
  overflow: hidden;

  img {
    width: 100%;
  }

  .row {
    @extend .g-0;
  }
}

.card:not(.card--sm) {
  box-shadow: $card-box-shadow;
}

.card-body {
  background-color: transparent;
  padding: calc($spacer * 1.5);
}

.card-title {
  color: $primary;
  margin-bottom: calc($spacer / 2);
  @include clamp-3;
  align-items: center;
  display: flex;
}

.card-subtitle {
  color: $text-muted;
  margin-bottom: calc($spacer / 4);
  font-size: $font-size-sm;
}

.card-text {
  @include clamp-3;
}

.card-link {
  display: flex;
  align-items: center;
}

.card--sm-h {
  display: flex;
  background-color: transparent;
  border-radius: 0 !important;
  flex-direction: row;

  .card-body {
    padding: 0 0 0 calc($spacer * 1.5);
    align-items: center;
    display: flex;
  }

  .card-link {
    margin-bottom: calc($spacer / -2.5);
  }
}

.card--md {
  .card-link {
    margin-top: $spacer;
    margin-bottom: calc($spacer / -2.5);
  }
}

.card--md-h {
  .card-title {
    margin-bottom: 0;
  }

  .list-taxonomie {
    margin-bottom: calc($spacer / 2);
    display: none;
  }

  .card-subtitle,
  .card-text {
    display: none;
  }
}

.card--md-testimonial {
  .card-title {
    margin-bottom: .25rem !important;
  }

  .card-text {
    display: block;
  }

  .list-review {
    margin-bottom: .5rem;
  }
}

.card-md-h-q {
  .card-link {
    display: none;
  }
}

.card--lg-h {
  .card-link {
    margin-top: $spacer;
    margin-bottom: calc($spacer / -2.5);
    display: none;
  }
}

.card--lg-table {
  box-shadow: $box-shadow-lg;
  background-color: $white;

  .card-title {
    justify-content: space-between;
  }

  .table {
    tr:last-of-type {
      td {
        border-bottom: 0;
      }
    }
  }
}

.card--lg-icon {
  transition: .15s all ease-in-out;
  box-shadow: $box-shadow-lg;
  background-color: $white;
  height: 100%;

  &:hover {
    box-shadow: 0 0 3rem rgba($dark, .25);
  }

  .card-body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  .card-text {
    @include clamp-4;
  }

  .card-link {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      color: $primary;
    }
  }
}

.card--md-v-teaser {
  .card-title {
    margin-bottom: -0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
  }

  .card-subtitle {
    margin-top: -0.4rem;
  }
}

@include media-breakpoint-up(sm) {
  .card-body {
    padding: calc($spacer * 2);
  }

  .card--md-h {
    .list-taxonomie {
      display: flex;
    }

    .card-title {
      margin-bottom: $spacer;
    }

    .card-link {
      display: block;
    }
  }
}

@include media-breakpoint-up(md) {
  .card {
    margin-bottom: 2rem;
  }

  .card--md-h {
    .card-subtitle,
    .card-text {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .card--lg {
    .card-body {
      padding: calc($spacer * 3);
    }
  }

  .card--lg-h {
    .card-link {
      display: flex;
    }
  }

  .card--lg-icon {
    .card-link {
      margin-top: -1rem;
      margin-bottom: -0.75rem;
    }
  }

  .card--xl {
    .card-body {
      padding-top: calc($spacer * 4);
      padding-bottom: calc($spacer * 4);
    }
  }
}
