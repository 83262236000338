.navbar-inverse {
  ~ .header--fullscreen,
  ~ .header--halfscreen {
    margin-top: -$nav-height;
  }
}

body.sticky {
  .page {
    padding-top: $nav-height;
  }
}

.navbar {
  height: $nav-height;
}

@include media-breakpoint-up(sm) {
  .navbar-inverse {
    ~ .header--fullscreen,
    ~ .header--halfscreen {
      margin-top: -$nav-height-sm;
    }
  }

  body.sticky {
    .page {
      padding-top: $nav-height-sm;
    }
  }

  .navbar {
    height: $nav-height-sm;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-inverse ~ {
    .header--fullscreen,
    .header--halfscreen {
      margin-top: -$nav-height-lg;
    }
  }

  body.sticky {
    .page {
      padding-top: $nav-height-lg;
    }
  }

  .navbar {
    height: $nav-height-lg;
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    height: $nav-height-xl;

    .navbar-nav .nav-link {
      height: $nav-height-xl;
    }
  }

  .navbar-inverse ~ {
    .header--fullscreen,
    .header--halfscreen {
      margin-top: -$nav-height-xl;
    }
  }

  body.sticky {
    .page {
      padding-top: $nav-height-xl;
    }

    .sticky-xl-top {
      top: calc($nav-height-xl + 2rem);
    }
  }
}
