.alert {
  .alert-success {
    a {
      color: #245b17;
    }
  }

  .alert-danger {
    a {
      color: #791d26;
    }
  }

  .alert-warning {
    a, h3 {
      color: #736400;
    }
  }
}

.alert-item {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 800;
  top: 42%;
  right: 4%;

  @include media-breakpoint-down(lg) {
    top: 37%;
  }
}
