body.typo {
  font-family: $font-family-sans-serif-alternate;
  font-size: $font-size-base * $font-size-base-factor;

  h1, h2, h3 {
    &:not(i) {
      font-family: $font-family-serif-alternate;
    }
  }

  .navbar a,
  h4, h5, h6,
  .btn,
  .lead {
    font-family: $font-family-sans-serif-alternate;
  }

  .navbar .nav-link {
    font-family: $font-family-sans-serif-alternate;
    font-size: $font-size-lg * $font-size-base-factor;
  }

  .btn {
    font-size: $font-size-base * $font-size-base-factor;
  }

  .h3:not(i) {
    font-size: $h3-font-size * $font-size-base-factor;
    font-weight: 500;
  }

  @include media-breakpoint-up(xl) {
    .lead {
      font-size: ($lead-font-size * $font-size-base-factor) * 1.35;
      line-height: 1.4;
    }
  }
}
