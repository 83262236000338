.dropdown-menu {
  right: 0;
  left: 0;
  transition: opacity .3s ease-in-out, margin-top .3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: -10;
  display: block;
  margin-top: .5rem;
}

.nav-item-mega {
  .nav-link {
    position: relative;

    &:before {
      bottom: -0.5rem;
      transition: bottom .3s ease-in-out, opacity .3s ease-in-out;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 14px 14px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      content: "";
      visibility: hidden;
    }
  }

  &.nav-item:not(.nav-item-click):hover,
  &.nav-item.active {
    .nav-link {
      &:before {
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    .dropdown-menu {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
}
