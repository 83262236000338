.template-default {
  .main {
    @extend .container;
  }

  &.overlap {
    .main {
      @extend .mt-xl-n10, .z-index-10;
    }

    .header--center-light {
      @extend .pb-xl-10;

      ~ .main {
        @extend .mt-xl-n6;
      }
    }
  }

  .sidebar {
    .card {
      background-color: transparent;
      box-shadow: none;

      .card-body {
        padding: 0;
      }

      .list-taxonomie {
        &:before {
          background: linear-gradient(270deg, rgba($body-bg,1) 0%, rgba($body-bg,0) 100%);
        }
      }
    }
  }
}

.template-sidebar {
  .main {
    @extend .container;
  }

  &.overlap {
    .sidebar {
      @extend .mt-xl-n8;
    }
  }

  &:not(.darkmode) {
    .sidebar {
      .card {
        box-shadow: $box-shadow-lg;
        background-color: $white;

        .list-taxonomie {
          &:before {
            background: linear-gradient(270deg, rgba($white,1) 0%, rgba($white,0) 100%);
          }
        }
      }
    }
  }
}
