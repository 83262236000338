.sidebar {
  .card {
    .row {
      align-items: center;
      @extend .g-2;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .list-taxonomie {
      margin-bottom: .5rem;
    }
  }
}
