// 
// _theme.scss
// Use this to add custom theme styles
//

.navbar {
  &.navbar-inverse {
    .navbar-brand {
      h4, p {
        color: $white !important;
      }
    }
  }
}

.link-before {
  i.fa-horizontal-rule {
    top: 1px;
    position: relative;
  }
}

body:not(.darkmode) .img-placeholder {
  filter: hue-rotate(52deg) opacity(0.8);
}

@include media-breakpoint-up(xl) {
  .display-2 {
    font-size: 4.3rem;
    line-height: 1.2;
  }

  body {
    padding: 1.5rem !important;
    background-color: $white;
  }

  body:not(.sticky) .navbar-inverse ~ .header--fullscreen {
    margin-top: calc(-120px);
    height: calc(100vh - 3rem) !important;
    min-height: calc(100vh - 3rem) !important;

    .vh-lg-100 {
      height: calc(100vh - 3rem) !important;
      min-height: calc(100vh - 3rem) !important;
    }
  }

  body:not(.sticky) .navbar-inverse ~ .header--halfscreen {
    margin-top: calc(-120px);
    min-height: calc(75vh - 3rem) !important;
    height: calc(75vh - 3rem) !important;

    .vh-75 {
      min-height: calc(75vh - 3rem) !important;
      height: calc(75vh - 3rem) !important;
    }
  }

  .navbar-inverse ~ .header--fullscreen,
  .navbar-inverse ~ .header--halfscreen {
    border-radius: 1.5rem 1.5rem 0 0 !important;
  }

  body.sticky .navbar {
    padding: 1rem 3rem !important;
  }

  .page {
    background-color: $gray-100;
    border-radius: 1.5rem;
  }
}
