sup {
  top: -0.75em;
}

blockquote,
.blockquote {
  line-height: 1.3;
  font-size: 2.25rem;
  font-style: italic;
}

h1, h2, h3 {
  &:not(i) {
    font-weight: $headings-font-weight;
    color: $primary;
  }
}

.bg-primary {
  &.text-light,
  .text-light {
    color: #dee7f1 !important;
  }

  .text-muted {
    color: $white !important;
    opacity: .5;
  }
}

.blockquote-footer {
  font-size: 1em;

  cite {
    font-style: normal;
  }
}

.lead {
  font-size: 1.65rem;
  line-height: 1.4;
  font-style: normal;
  font-weight: 200;
}

@mixin clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

@include media-breakpoint-up(xl) {
  .display-1 {
    font-size: 6.6rem;
    line-height: 1;
  }

  .display-2 {
    font-size: 4.6rem;
    line-height: 1;
  }

  .display-5 {
    font-size: 3.45rem;
    line-height: 1.2;
  }

  .display-6 {
    font-size: 6.6rem;
    line-height: 1.2;
  }
}

@include media-breakpoint-up(xxl) {
  .lead {
    font-size: 2.25rem;
    line-height: 1.4;
    font-style: normal;
  }

  .display-1 {
    font-size: 6.4rem;
    line-height: 1;
  }

  .display-2 {
    font-size: 5.6rem;
    line-height: 1;
  }

  .display-5 {
    font-size: 5.25rem;
    line-height: 1.2;
  }

  .display-6 {
    font-size: 6.6rem;
    line-height: 1.2;
  }
}
