$gray-100-dark: #f7f7f7 !default;
$gray-200-dark: #e9ecef !default;
$gray-300-dark: #dee2e6 !default;
$gray-400-dark: #ced4da !default;
$gray-500-dark: #97a3af !default;
$gray-600-dark: #6c757d !default;
$gray-700-dark: #495057 !default;
$gray-800-dark: #343a40 !default;
$gray-900-dark: #212529 !default;

body.darkmode {
  background-color: $gray-900-dark;
  color: $gray-400-dark;

  a:not(.btn):not(.location-link) {
    color: $white !important;

    &:hover {
      color: $gray-400-dark !important;
    }
  }

  .bg-body {
    background-color: $gray-900-dark !important;
  }

  .header {
    &.header--center-light,
    &.header--sidebar {
      background-color: $gray-800-dark;
    }
  }

  .card {
    background-color: $gray-800-dark;
  }

  .card--lg-icon {
    i {
      color: $white;
    }
  }

  .mm-page {
    background-color: $gray-900-dark;
  }

  .badge,
  .badge.text-white {
    color: $white !important;
  }

  .navbar-brand-color {
    fill: $white !important;
  }

  h1, h2, h3, h4 {
    color: $white !important;
  }

  .cover {
    h1, h2 {
      color: $white !important;
    }
  }

  .text-muted {
    color: $gray-600-dark !important;
  }

  .text-white,
  .text-body,
  .text-dark,
  .dropdown-menu,
  .text-white.fa-light {
    color: $white !important;
  }

  .dropdown-menu {
    color: $gray-400-dark !important;

    .text-primary {
      color: $gray-600-dark !important;
    }
  }

  .bg-switch {
    background-color: $gray-800-dark;
  }

  .bg-light,
  .mm-panels {
    background-color: $gray-800-dark !important;

    + .container {
      > .bg-white {
        background-color: $gray-900-dark !important;
      }
    }

    .bg-white {
      background-color: $gray-900-dark !important;
    }
  }

  .bg-primary {
    .text-white {
      color: $white !important;
    }
  }

  .bg-white {
    background-color: $gray-800-dark !important;

    .bg-light {
      background-color: $gray-700-dark !important;
    }

    .bg-white.shadow-lg {
      background-color: $gray-900-dark !important;
    }
  }

  .shadow-lg,
  .hover-shadow {
    box-shadow: 0 0 3rem rgba($black, .25) !important;
  }

  .fa-light.text-primary,
  .fal.text-primary {
    color: $gray-600-dark !important;
  }

  &.sticky .navbar {
    &:not(.navbar-dark) {
      background-color: $gray-900-dark !important;
    }

    &.navbar-scroll {
      box-shadow: 0 0 3rem rgba($black, .35);
      background-color: rgba($gray-900-dark, .85) !important;
      backdrop-filter: blur(30px);

      &.navbar-light {
        .fa-bars,
        .fa-brightness,
        .fa-moon {
          color: $white !important;
        }
      }

      .btn-outline {
        color: $white;
        border-color: $gray-700-dark;

        &:hover {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
  }

  .navbar {
    .navbar-nav .nav-link {
      color: rgba(255,255,255,.75);

      &:hover {
        color: $white;
      }
    }

    .fa-light {
      color: $white;
    }

    .btn-outline {
      color: $white;
      border-color: $white;

      &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;
      }
    }
  }

  .navigation {
    .nav-link {
      &:before {
        border-color: transparent transparent $gray-800-dark transparent;
      }
    }
  }

  .bg-dark {
    background-color: $white !important;

    &.badge {
      background-color: $gray-800-dark !important;
    }
  }

  .media-backdrop {
    background-color: rgba(33, 37, 41, .45);
  }

  .modal-backdrop-menu.modal-backdrop-light,
  .modal-backdrop.modal-backdrop-light {
    background-color: $black;
    opacity: .85;
  }

  .modal-backdrop.show {
    opacity: 0.85;
  }

  .scroll-x {
    &:before {
      background: linear-gradient(270deg, rgba($gray-800-dark,1) 0%, rgba($gray-800-dark,0) 100%);
    }
  }

  &.template-default .sidebar .card .list-taxonomie:before {
    background: linear-gradient(270deg, rgba($gray-900-dark,1) 0%, rgba($gray-900-dark,0) 100%);
  }

  .btn {
    &.bg-white {
      color: $white;
    }
  }

  .btn-outline-primary,
  .btn-primary-light {
    color: $white;
    border-color: $white;
    background-color: transparent;

    &:hover {
      border-color: $primary;
      background-color: $primary;
    }
  }

  .btn-share {
    color: $white !important;

    &:hover {
      color: $gray-900-dark !important;
    }
  }

  #btn-toggle-nav {
    color: $white !important;

    &:hover {
      color: $gray-400-dark !important;
    }
  }

  .btn-icon-pill {
    color: $gray-200-dark !important;
    background-color: $gray-700-dark;
    border-color: $gray-700-dark;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .nav-pills .nav-link:not(.active) {
    color: $white;
    border-color: $gray-600-dark;

    &:hover {
      border-color: $primary;
      background-color: $primary;
    }
  }

  .accordion-button {
    color: $white;
  }

  .accordion-button:not(.collapsed) {
    background-color: $gray-800-dark;
  }

  .form-control,
  .form-select,
  .form-check-input:not(:checked) {
    background-color: rgba($white, .05) !important;
    border-color: $gray-600-dark;
    color: $white;

    &:focus {
      border-color: $gray-400-dark;
    }
  }


  .form-check-theme {
    input[type="radio"]:checked + .form-check-label-theme {
      color: $white;
    }
  }

  .border,
  .border-bottom,
  .border-top,
  .border-start,
  .border-end,
  .accordion-item,
  .modal-header,
  .table {
    border-color: $gray-700-dark !important;
  }

  .alert-warning {
    background-color: darken($yellow, 24%);
    color: $white;
  }

  .accordion-button::after {
    background-color: $gray-400-dark;
    border-radius: 0.25rem;
    background-size: 15px;
    background-position: center;
  }

  .bordered:after {
    background-color: $gray-700-dark;
  }

  a.link-before {
    .fa-chevron-right,
    + .fa-chevron-right {
      color: $gray-600-dark !important;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: transparent;
    border: 1px solid $gray-600-dark;

    &:after {
      color: $white;
    }

    &:hover {
      background-color: $gray-600-dark;
    }
  }

  .modal-content {
    background-color: $gray-900-dark;
    box-shadow: 0 0 4rem rgba($black, .75) !important;
  }

  .modal-title {
    color: $white !important;
  }

  .modal-header {
    .btn-close {
      background-color: #ffffffcc;
      opacity: 1;

      &:hover {
        background-color: #ffffff;
      }
    }
  }

  .table {
    color: $gray-400-dark;

    .text-primary {
      color: $gray-600-dark !important;
    }
  }

  .header--collage {
    .border-light {
      border-color: $gray-900-dark !important;
    }
  }

  &.frame {
    background-color: $gray-800-dark;

    #page {
      background-color: $gray-900-dark;
    }
  }

  .mm-menu {
    --mm-color-text: #CED4DAFF;
    --mm-color-background-highlight: #ffffff;
    --mm-color-border: #495057FF;
    --mm-color-background: #343A40FF;
    background: #343A40FF;

    .mm-listitem,
    .mm-navbar {
      border-color: $gray-700-dark;

      &:after {
        border-color: $gray-700-dark !important;
      }
    }

    .mm-btn--next:after,
    .mm-btn--prev:before {
      border-color: $gray-600-dark !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .bg-lg-white {
      background-color: $gray-800-dark;
    }
  }

  @include media-breakpoint-up(xl) {
    .bg-xl-light {
      background-color: $gray-800-dark;
    }

    .bg-xl-switch {
      background-color: $gray-800-dark;
    }
  }
}
