body.sticky {
  .navbar {
    position: fixed !important;
    top: 0;

    &:not(.navbar-dark) {
      background-color: $body-bg;

      &.navbar-inverse {
        .navbar-brand-color {
          fill: $primary;
          color: $primary;
        }
      }
    }

    &.navbar-scroll {
      box-shadow: $box-shadow-lg;
    }
  }

  &:not(.darkmode) {
    .navbar {
      &.navbar-scroll {
        .navbar-brand-color {
          fill: $primary;
        }

        .fa-light {
          color: $body-color;
        }

        .btn-outline {
          border-color: $border-color;
          color: $body-color;

          &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white;
          }
        }

        .logo-light {
          display: inline-block;
        }

        .logo-dark {
          display: none;
        }
      }
    }
  }
}
