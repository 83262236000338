.cover {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 120px;

  img, video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &.cover-lg {
    //min-height: 740px;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }

  &.cover-full {
    min-height: 660px;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }

  &.cover-height {
    min-height: 435px;
  }
}

@include media-breakpoint-down(sm) {

}
