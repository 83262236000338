.modal-backdrop {
  z-index: 8000;
}

.modal-backdrop.modal-backdrop-light,
.modal-backdrop-menu.modal-backdrop-light {
  background-color: #fff;
  z-index: -10;

  &.show {
    opacity: .95;
    z-index: 10;
  }
}
