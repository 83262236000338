.mm-slideout:not(.mm-blocker--blocking) {
  position: initial;
}

.mm-wrapper--position-right-front .mm-wrapper__blocker {
  z-index: 8000;
}

.mm-menu {
  --mm-listitem-size: 70px;
  --mm-navbar-size: 70px;
  --mm-lineheight: 1.85rem;
  --mm-color-text: #212529FF;
  --mm-color-background-highlight: #F7F7F7FF;
  --mm-color-border: #E9ECEFFF;

  z-index: -5;

  &.mm-menu--opened {
    z-index: 80000;
  }

  a.mm-listitem__text {
    font-weight: 500;
    color: $primary;

    &:hover {
      color: darken($primary, 5%);
    }
  }

  .mm-btn {
    &:hover {
      &:after, &:before {
        border-color: $primary;
      }
    }
  }

  .mm-panel {
    padding: 0;

    .Vertical {
      .mm-listitem:after {
        border-color: $gray-300;
      }
    }

    .mm-listitem--vertical {
      > .mm-panel {
        border-top: 1px solid $gray-300;
      }
    }
  }

  .mm-listitem--selected > .mm-listitem__text {
    background: transparent;
    color: $primary;
  }

  .mm-listitem:after {
    border-color: $gray-200;
    inset-inline-start: 0;
  }
}

#menu:not(.mm-menu--offcanvas) {
  display: none;
}

.mm-blocker--blocking {
  --mm-blocker-opacity-delay: 0.05s;
  background-color: $dark;
}
