/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '../../../node_modules/bootstrap/scss/functions';

// Custom variables
@import 'theme-modern/user-variables';

// Bootstrap core
@import '../../../node_modules/bootstrap/scss/bootstrap';

/*! purgecss start ignore */
// MM Menu
@import '../../../node_modules/mmenu-js/dist/mmenu';

// Swiper Js
@import '../../../node_modules/swiper/swiper';
/*! purgecss end ignore */

// Fontawesome
@import 'fontawesome/fontawesome';

// Theme core
@import 'theme/theme';
@import 'theme-modern/theme';
