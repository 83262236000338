.btn {
  &:focus {
    box-shadow: none !important;
  }

  .fal {
    transition: .125s all ease-in-out;
  }
}

.btn-primary {
  color: $white;

  &:hover {
    color: $white;

    .fal {
      color: $white;
    }
  }
}

.btn-primary-light {
  color: $primary;
  background-color: mix($primary, $white, 12.5%);

  &:hover {
    color: $white;
    background-color: $primary;

    .fal {
      color: $white;
    }
  }
}

.btn-outline-primary {
  &:hover {
    .fal {
      color: $white;
    }
  }
}

.btn-light {
  color: $primary;

  &:hover {
    background-color: $white;
    color: $primary;
  }
}

.btn-outline-secondary {
  &:hover {
    color: $white;
  }
}

.btn-fluid-group {
  left: inherit;
  bottom: 0;

  .fal {
    width: 20px;
  }

  .btn.bg-white:focus {
    box-shadow: $box-shadow !important;
  }

  .fluid-item {
    transition: .25s right ease-in-out;
    right: -270px;
    justify-content: flex-end;
    position: relative;
    align-items: end;

    .fluid-inner {
      width: 270px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .btn {
      border-right: 1px solid rgba(0,0,0,0.05) !important;
    }

    &.e-mail {
      right: -155px;

      .btn-primary,
      .bg-primary {
        background-color: darken($primary, 5%) !important;
      }

      .btn-primary {
        border-color: darken($primary, 5%) !important;

        &.btn {
          border-right: 1px solid rgba(0,0,0,0.05) !important;
        }
      }

      .fluid-inner {
        width: 155px;
      }
    }
  }

  .fluid-item {
    &.active {
      right: 0;
    }
  }
}

a.link-before {
  .fa-chevron-right,
  + .fa-chevron-right {
    position: relative;
    transition: .125s left ease-in-out;
    left: 0;
  }

  &:hover {
    .fa-chevron-right,
    + .fa-chevron-right {
      left: 3px;
    }
  }
}

.link-primary,
.dropdown-menu ul > li > a {
  .fa-chevron-right {
    position: relative;
    transition: .125s left ease-in-out;
    left: 0;
  }

  &:hover {
    .fa-chevron-right {
      left: 3px;
    }
  }
}

.btn-outline-primary {
  &:hover {
    color: $white;
  }
}

.btn-share {
  border-radius: $border-radius;

  &:hover {
    background-color: $gray-100;
  }
}

.bg-light .btn-share {
  &:hover {
    background-color: $white;
  }
}

.btn-icon-pill {
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-switch;
  color: $primary;

  &:hover {
    border-color: $primary;
  }
}

.btn-360,
.btn-play {
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  font-size: 2.5rem;

  &.btn-sm {
    width: 55px;
    height: 55px;
    font-size: 1.5rem;

    &:before {
      display: none;
    }

    &:hover {
      .fa-play {
        opacity: 1;
        display: inline;
      }
    }
  }

  &:before {
    content: "Start";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: .15s all ease-in-out;
    opacity: 0;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    z-index: 3;
    background-color: $primary;
    border-radius: 5rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .fa-play {
    right: -4px;
    bottom: -2px;
    position: relative;
    opacity: 1;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .fa-play {
      opacity: 0;
      display: none;
    }
  }
}

.btn-play {
  &:before {
    content: "Play";
  }
}

.btn-overlay-close {
  color: $text-muted;

  &:hover {
    color: $body-color;
  }
}

#scrollTopBtn {
  opacity: 0;
  transition: .25s all ease-in-out;
  border: transparent;

  &.active {
    opacity: 1;
  }
}

.btn-pair {
  .btn {
    @extend .min-with-200;
    width: 100%;
    display: block;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .btn-pair {
    display: flex;
    align-items: center;

    &.btn-pair--center {
      justify-content: center;
    }

    .btn {
      width: auto;
      display: inline-block;
      margin-bottom: 0 !important;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .btn-share {
    min-width: 140px;
  }
}

@include media-breakpoint-up(xxl) {
  .btn-share {
    min-width: 165px;
  }
}
