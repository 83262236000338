.form-check-custom {
  position: relative;

  input[type="checkbox"]:checked + .form-check-label {
    border-color: $primary !important;
    color: $primary;

    &:after {
      opacity: 1;
      border: 1px solid $primary;
      background-color: $primary;
      color: $white;
    }

    &:hover {
      border-color: $primary !important;
    }
  }

  .form-check-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;


    &:after {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -5px;
      font-size: 0.8rem;
      top: -5px;
      content: "\f00c";
      border: 1px solid $border-color;
      background-color: $white;
      opacity: 0;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Font Awesome 6 Pro';
      font-weight: 100;
    }

    &:hover {
      border-color: $border-color !important;

      &:after {
        opacity: 1;
        background-color: $primary;
        color: $white;
        border-color: $primary !important;
      }
    }
  }

  .input-absolute {
    display: none;
  }
}

.form-check-theme {
  &:hover .form-check-label-theme {
    border-color: $primary !important;
    cursor: pointer;
  }

  &.active .form-check-label-theme {
    border-color: $primary !important;
    color: $primary;
  }
}

.input-group {
  .btn {
    z-index: 1;
  }

  .form-search {
    border-radius: .15rem 0 0 .15rem !important;
  }
}

.custom-switcher {
  min-height: 2.45rem;
  margin: 0 0 0 0.6rem;
  align-items: center;
  display: flex;

  .form-check-input {
    width: 2.1em !important;
    height: 1.2em !important;
    margin-top: 0;
    margin-right: 0.5rem;
  }
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  border-color: #ade6cd;
}

dl.errors {
  font-size: 0.75rem;
  color: $danger;

  dt {
    font-weight: normal;
  }
}

.custom-switch .custom-control-label::before {
  right: 1rem;
  left: inherit;
  top: 0;
  bottom: 0;
  margin: auto;
}

.custom-switch .custom-control-label::after {
  right: 2.2rem;
  left: inherit;
  top: 0;
  bottom: 0;
  margin: auto;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: white;
}

.filter-toggle.active {
  background-color: $black;
  color: $white;
}

footer {
  .btn-absolute {
    top: 1px;
  }

  #mc_embed_signup {
    margin-top: 1.25rem;

    label {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .form-check-custom {
    input[type="checkbox"]:checked + .form-check-label {
      &:hover {
        &:after {
          content: "\f00d";
        }
      }
    }
  }
}
