.fadeIn {
  animation: .5s fadeIn ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: -2rem;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    filter: blur(5px);
    opacity: .85;
  }
  65% {
    transform: scale(.85);
    filter: blur(10px);
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    top: 0;
  }
  65% {
    top: 5px;
  }
}
